export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    favorite: language === 'pt-BR' ? 'Favoritos' : 'Favorites',

    perfilPerformance: language === 'pt-BR' ? 'Perfil Performance' : 'Performance People',
    crescimentoCruzado: language === 'pt-BR' ? 'Crescimento Cruzado' : 'Gravity Growth',
    pipePerformance: language === 'pt-BR' ? 'Pipe Performance' : 'Pipe Performance',

    transformation: language === 'pt-BR' ? 'Imersão' : 'Transformation',
    accelerators: language === 'pt-BR' ? 'Aceleradores' : 'Accelerators',
    consulting: language === 'pt-BR' ? 'Aceleração' : 'Acceleration',
    intelligence: language === 'pt-BR' ? 'Inteligência' : 'Intelligence',
    groupMettings: language === 'pt-BR' ? 'Reuniões em Grupo' : 'Group Meetings',
    reports: language === 'pt-BR' ? 'Relatórios' : 'Reports',
    digitalConsulting: language === 'pt-BR' ? 'Consultor Digital' : 'Digital Consultant',

    accelerationProgram: language === 'pt-BR' ? 'Programa de Aceleração' : 'Acceleration Program',
    dashboardExecutivoMacro: language === 'pt-BR' ? 'Executivo Macro' : 'Macro Executive',
    dashboardExecutivoMicro: language === 'pt-BR' ? 'Executivo Micro' : 'Micro Executive',

    labelAILabsNewProject: language === 'pt-BR' ? 'Novo Lab' : 'New Lab',
    labelAILabsManageProjects: language === 'pt-BR' ? 'Meus Labs' : 'My Labs',

    userTracker: language === 'pt-BR' ? 'Tracker' : 'Tracker',

    language,
  };
}
