import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

export const LoaderContainer = styled.div`
  display: grid;
  width: 100%;
  place-items: center;
  margin-top: 8px;
`;

export const QuestionArea = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
`;

export const QuestionAreaTwoColumns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }

  .container-selects {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  > label {
    margin-bottom: 4px;
  }

  .radio-area {
    display: flex;
    align-items: center;
    gap: 16px;

    label {
      display: flex;
      align-items: center;
      gap: 2px;
    }

    input[type="radio"] {
      accent-color: ${({ theme }) => theme.blue} !important;
    }

    input[type="radio"]:checked {
      background-color: ${({ theme }) => theme.blue} !important;
    }
  }

  textarea {
    width: 100%;
    min-height: 70px;
    resize: none;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-bottom: 0;
  }

  .btns-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    background-color: ${({ theme }) => theme.gray1};
    padding: 4px 8px;
    font-size: 13px;
    border-bottom-right-radius: ${({ $open }) => ($open ? '0px' : '4px')} !important;
    border-bottom-left-radius: ${({ $open }) => ($open ? '0' : '4px')} !important;

    div {
      color: ${({ theme }) => theme.gray4};
      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.gray4};
      padding: 4px 8px;
      border-radius: 4px;

      &:hover {
        color: ${({ theme }) => theme.gray5};
        border: 1px solid ${({ theme }) => theme.gray5};
      }
    }

    .btns-action {
      display: flex;
      align-items: center;
      gap: 8px;

      > img {
        width: 13px;
        filter: invert(19%) sepia(4%) saturate(5%) hue-rotate(351deg) brightness(96%) contrast(93%);
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: 10px;
      }
    }

    .btns-action.open-modal {
      > img {
        width: 13px;
        transform: ${({ $open }) => ($open ? 'rotate(-180deg)' : 'rotate(0deg)')} ;
        transition: 0.3s ease;
      }
    }
  }

  .options-area {
    display: ${({ $open }) => ($open ? 'grid' : 'none')};
    padding: 8px !important;
    background-color: ${({ theme }) => theme.gray1};
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    transform: translateY(0);
    transition: transform .3s ease-in-out;
    width: 100%;
    font-size: 13px;
  }
`;

export const ResponseArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResponseContainer = styled.div`
  h4 {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 8px;
    margin-top: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.gray4};
    color: ${({ theme }) => theme.gray4}
  }

  .response-area {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
    gap: 16px;
    margin-top: 8px;

    /* Adicione estas propriedades para alinhar os cards */
    & > * {
      display: flex;
      flex-direction: column;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.gray1};
  border-radius: 4px;
  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
  height: ${({ $visible }) => ($visible ? '450px' : '0%')};

  .title {
    background-color:${({ theme }) => theme.gray1};
    border-radius: inherit;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;

    span {
      cursor: ${({ $visible }) => ($visible ? 'normal' : 'pointer')};
      width: 100%;
      text-align: center;
      font-weight: 500;
    }

    img {
      cursor: pointer;
      display: inline-block;
      transition: 0.2 ease-in-out;
      filter: invert(99%) sepia(7%) saturate(312%) hue-rotate(195deg) brightness(113%) contrast(89%);
      width: 13px;
    }

    img:hover {
      filter: invert(73%) sepia(0%) saturate(169%) hue-rotate(196deg) brightness(91%) contrast(85%);
    }
  }

  .infos {
    overflow-y: scroll;
    padding: 16px;
    background-color:${({ theme }) => theme.grayMenu};
    height: inherit;
    width: 100%;
    display: ${({ $visible }) => ($visible ? 'inline-block' : 'none')};

    // Important to format texts in each card
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;
