import React from 'react';
import IconCPU from '../../../../../styles/logos/icon_cpu.svg';

import { Container, CardArea, Card } from './style';
import { Button } from '../../../../../components/Button';
import { Badge } from '../../../../../components/Badge';
import { Translate } from './translate';

export function ButtonsArea({
  buttonsArea, onGetDataSecondStage, onGetDataThirdStage, onGetDataFourthStage, onGetDataFifthStage, onGetDataSixthStage,
}) {
  const translate = Translate();

  const showTitle = buttonsArea.buttons.second
                    || buttonsArea.buttons.third
                    || buttonsArea.buttons.fourth
                    || buttonsArea.buttons.fifth
                    || buttonsArea.buttons.sixth;

  return (
    <Container>
      {(showTitle) && (
        <h2>{translate.mainTitle}</h2>
      )}
      <CardArea>
        {buttonsArea.buttons.second && (
        <Card>
          <div className="card-content">
            <div className="logo">
              <img src={IconCPU} alt="Logo" />
            </div>
            <div className="content">
              <div className="content-title">
                <h3>{translate.titleVSLPolicyFree}</h3>
                <Badge>{translate.estimatedTime}: <strong>60-90 s</strong></Badge>
              </div>
              <p>{translate.msgVSLPolicyFree}</p>
            </div>
          </div>
          <Button color="blue" onClick={() => onGetDataSecondStage()}> {translate.btn} </Button>
        </Card>
        )}

        {buttonsArea.buttons.third && (
        <Card>
          <div className="card-content">
            <div className="logo">
              <img src={IconCPU} alt="Logo" />
            </div>
            <div className="content">
              <div className="content-title">
                <h3>{translate.titleAssetsCopy}</h3>
                <Badge>{translate.estimatedTime}: <strong>2-3 min</strong></Badge>
              </div>
              <p>{translate.msgAssetsCopy}</p>
            </div>
          </div>
          <Button color="blue" onClick={() => onGetDataThirdStage()}> {translate.btn} </Button>
        </Card>
        )}

        {buttonsArea.buttons.fourth && (
        <Card>
          <div className="card-content">
            <div className="logo">
              <img src={IconCPU} alt="Logo" />
            </div>
            <div className="content">
              <div className="content-title">
                <h3>{translate.titleEngineValidation}</h3>
                <Badge>{translate.estimatedTime}: <strong>20-50 s</strong></Badge>
              </div>
              <p>{translate.msgEngineValidation}</p>
            </div>
          </div>
          <Button color="blue" onClick={() => onGetDataFourthStage()}> {translate.btn} </Button>
        </Card>
        )}

        {buttonsArea.buttons.fifth && (
        <Card>
          <div className="card-content">
            <div className="logo">
              <img src={IconCPU} alt="Logo" />
            </div>
            <div className="content">
              <div className="content-title">
                <h3>{translate.titleScaleCopy}</h3>
                <Badge>{translate.estimatedTime}: <strong>2-3 m</strong></Badge>
              </div>
              <p>{translate.msgScaleCopy}</p>
            </div>
          </div>
          <Button color="blue" onClick={() => onGetDataFifthStage()}> {translate.btn} </Button>
        </Card>
        )}

        {buttonsArea.buttons.sixth && (
        <Card>
          <div className="card-content">
            <div className="logo">
              <img src={IconCPU} alt="Logo" />
            </div>
            <div className="content">
              <div className="content-title">
                <h3>{translate.titleVideoAds}</h3>
                <Badge>{translate.estimatedTime}: <strong>2-3 m</strong></Badge>
              </div>
              <p>{translate.msgVideoAds}</p>
            </div>
          </div>
          <Button color="blue" onClick={() => onGetDataSixthStage()}> {translate.btn} </Button>
        </Card>
        )}
      </CardArea>
    </Container>
  );
}
