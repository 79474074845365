import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 16px;

  h2 {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 23px;
  }
`;

export const CardArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Card = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.gray1};
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);

  .card-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    gap: 8px;

    .logo {
      background-color: ${({ theme }) => theme.grayMenu};
      padding: 8px;
      border-radius: 4px;
    }

    .content {
      margin-bottom: 16px;

      p {
        color: ${({ theme }) => theme.gray5};
      }

      .content-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        h3, strong {
          font-weight: 500;
        }
      }
    }
  }

  button {
    width: 100%;
  }
`;
