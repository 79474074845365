export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  // Pattern => en-US
  const terms = {
    title: 'CopyCut',
    messageProccessInfo: 'We are organizing your information. This may take up to 90 seconds. Please do not leave this screen.',
    messageProccessInfoNoSec: 'We are organizing your information. Please do not leave this screen.',

    btnOne: 'Create VSL Copy (B2C/SMB e Enterprise)',

    h4TitleVslCopy: 'VSL Copy (B2C/SMB and Enterprise)',
    h4TitleVslPolicyFree: 'VSL Policy Free - B2C/SMB and Enterprise',
    h4TitleAssets: 'Assets Copy - Marketing and Sales',
    h4TitleEngineValidation: 'Engine Validation - Order Bump and Upsell',
    h4TitleScaleCopy: 'Scale Copy - Marketing Assets Copy for Sales and Marketing Teams',
    h4TitleGAKeywords: 'Google Ads - Keywords',
    h4TitleGAAnnouncementGroup: 'Google Ads - Ad Groups',
    h4TitleGAAnnouncement: 'Google Ads - Campaigns and Ad Groups',
    h4TitleMetaAds: 'Meta Ads - Budgets, Campaigns, Interests, Ads and Optimization',
    h4TitleTiktokAds: 'TikTok Ads - Video Formats, Viral Elements, Posting Strategies and Engagement Elements',
    h4TitleLinkedinAds: 'LinkedIn Ads - Targeting, Campaigns, Ads and Optimization',
    h4TitleVideoAds: 'Video Ads - Strategic Scripts for Digital Marketing',

    questionModel: 'Is your business B2C or B2B?',
    questionLanguage: 'What\'s the language of the answers?',
    questionToneOfVoice: 'Tone of Voice',
    questionAvatar: 'What is your perfect customer? (ICP / Audience / Persona / AVATAR)',
    questionNiche: 'What is your niche market? (Niche or mass market)',
    questionSite: 'Site Data / Landing Page Data / Competitors Data / Emails Data?',

    placeholderAvatar: 'Enter your avatar\'s information',
    placeholderNiche: 'Enter your niche\'s information',
    placeholderSite: 'Enter your site\'s information',

    titleVslSMBPolicyFree: 'VSL SMB (Policy Free)',
    titleVslEnterprisePolicyFree: 'VSL Enterprise (Policy Free)',
    titleSalesConversationalB2C: 'Sales Conversation B2C/ SMB',
    titleSalesConversationalEnterprise: 'Sales Conversation Enterprise',
    titleSingleMechanism: 'Unique Mechanism',
    titleSlideShow: 'Slideshow',
    titleTwentyNames: 'Product Names / Domains / Business',
    titleGAVideoAdsHistoricalEvolution: 'Historical Evolution',
    titleGAVideoAdsPersonaPainPoints: 'Persona Pain Points',
    titleGAVideoAdsTransformationTimeLapse: 'Transformation Time Lapse',
    titleGAVideoAdsWhistleblower: 'Whistleblower Control Beater',
    titleGAVideoAdsPermissionSkip: 'Skip Permission',
    titleGAVideoAdsUnexpectedDelivery: 'Unexpected Delivery',
    titleGAVideoAdsMithBuster: 'Myth Buster',
    titleGAVideoAdsWeirdHack: 'Security Breach',

    optionSellingMode: 'Selling Mode',
    optionFriendly: 'Friendly',
    optionProfessional: 'Professional',
    optionExpert: 'Expert',
    optionBusinessAndTrust: 'Business and Trust',
    optionSocialMedia: 'Social Media',
    optionSensitiveCommunication: 'Sensitive Communication',

    btnAddAll: 'Add all',
    btnDeleteAll: 'Delete all',
    btnExplore: 'Explore templates',
    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        title: 'CopyCut',
        messageProccessInfo: 'Estamos organizando suas informações. Isso pode levar até 90 segundos. Por favor, não saia desta tela.',
        messageProccessInfoNoSec: 'Estamos organizando suas informações. Por favor, não saia desta tela.',

        btnOne: 'Criar VSL Copy - B2C/SMB e Enterprise',

        h4TitleVslCopy: 'VSL Copy - B2C/SMB e Enterprise',
        h4TitleVslPolicyFree: 'VSL Livre de Política - B2C/SMB e Enterprise',
        h4TitleAssets: 'Assets Copy - Marketing e Vendas',
        h4TitleEngineValidation: 'Engine Validation - Order Bump e Upsell',
        h4TitleScaleCopy: 'Scale Copy - Marketing Assets Copy para Times de Vendas e Marketing',
        h4TitleGAKeywords: 'Google Ads - Palavras-chave',
        h4TitleGAAnnouncementGroup: 'Google Ads - Grupos de anúncio',
        h4TitleGAAnnouncement: 'Google Ads - Campanhas e Grupos de anúncio',
        h4TitleMetaAds: 'Meta Ads - Orçamentos, Campanhas, Interesses, Anúncios e Otimização',
        h4TitleTiktokAds: 'TikTok Ads - Formatos de Vídeo, Elementos Virais, Estratégias de Postagem e Elementos de Engajamento',
        h4TitleLinkedinAds: 'LinkedIn Ads - Segmentação, Campanhas, Anúncios e Otimização',
        h4TitleVideoAds: 'Video Ads - Roteiros Estratégicos para Marketing Digital',

        questionModel: 'Seu negócio é de ofertas B2C ou B2B?',
        questionLanguage: 'Qual o idioma das respostas?',
        questionToneOfVoice: 'Tom de Voz',
        questionAvatar: 'Qual é o seu cliente perfeito? (ICP / Audiência / Persona / AVATAR)',
        questionNiche: 'Qual é o seu nicho de mercado? (Nicho ou mercado de massas)',
        questionSite: 'Dados do site / Landing Page / Concorrentes/ Dados de e-mail?',

        placeholderAvatar: 'Insira as informações do seu avatar',
        placeholderNiche: 'Insira as informações do seu nicho',
        placeholderSite: 'Insira as informações do seu site',

        titleVslSMBPolicyFree: 'VSL SMB - Livre de Políticas',
        titleVslEnterprisePolicyFree: 'VSL Enterprise - Livre de Políticas',
        titleSalesConversationalB2C: 'Conversa de Vendas B2C/SMB',
        titleSalesConversationalEnterprise: 'Conversa de Vendas Enterprise',
        titleSingleMechanism: 'Mecanismo Único',
        titleSlideShow: 'Textos para Slides',
        titleTwentyNames: 'Nomes de Produtos / Domínios / Negócios',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Pontos de Dor da Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Linha do Tempo da Transformação',
        titleGAVideoAdsWhistleblower: 'Controlador de Denúncias Internas',
        titleGAVideoAdsPermissionSkip: 'Permissão para Pular',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Inesperada',
        titleGAVideoAdsMithBuster: 'Desmistificador',
        titleGAVideoAdsWeirdHack: 'Violação de Segurança',

        optionSellingMode: 'Modo Venda',
        optionFriendly: 'Amigável',
        optionProfessional: 'Profissional',
        optionExpert: 'Especialista',
        optionBusinessAndTrust: 'Negócios e Confiança',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicação Sensível',

        btnAddAll: 'Adicionar todos',
        btnDeleteAll: 'Deletar todos',
        btnExplore: 'Explorar templates',
        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        title: 'CopyCut',
        messageProccessInfo: 'Estamos a organizar as suas informações. Isto pode demorar até 90 segundos. Por favor, não saia deste ecrã.',
        messageProccessInfoNoSec: 'Estamos a organizar as suas informações. Por favor, não saia deste ecrã.',

        btnOne: 'Criar VSL Copy - B2C/SMB e Enterprise',

        h4TitleVslCopy: 'VSL Copy - B2C/SMB e Enterprise',
        h4TitleVslPolicyFree: 'VSL Livre de Política - B2C/SMB e Enterprise',
        h4TitleAssets: 'Assets Copy - Marketing e Vendas',
        h4TitleEngineValidation: 'Engine Validation - Order Bump e Upsell',
        h4TitleScaleCopy: 'Scale Copy - Cópia de Ativos de Marketing para Equipas de Vendas e Marketing',
        h4TitleGAKeywords: 'Google Ads - Palavras-chave',
        h4TitleGAAnnouncementGroup: 'Google Ads - Grupos de anúncios',
        h4TitleGAAnnouncement: 'Google Ads - Campanhas e Grupos de anúncio',
        h4TitleMetaAds: 'Meta Ads - Orçamentos, Campanhas, Interesses, Anúncios e Otimização',
        h4TitleTiktokAds: 'TikTok Ads - Formatos de Vídeo, Elementos Virais, Estratégias de Publicação e Elementos de Envolvimento',
        h4TitleLinkedinAds: 'LinkedIn Ads - Segmentação, Campanhas, Anúncios e Otimização',
        h4TitleVideoAds: 'Video Ads - Guiões Estratégicos para Marketing Digital',

        questionModel: 'O seu negócio é B2C ou B2B?',
        questionLanguage: 'Qual é o idioma das respostas?',
        questionToneOfVoice: 'Tom de Voz',
        questionAvatar: 'Qual é o seu cliente perfeito? (ICP / Audiência / Persona / AVATAR)',
        questionNiche: 'Qual é o seu nicho de mercado? (Nicho ou mercado de massas)',
        questionSite: 'Dados do site / Landing Page / Concorrentes / Dados de e-mail?',

        placeholderAvatar: 'Insira as informações do seu avatar',
        placeholderNiche: 'Insira as informações do seu nicho',
        placeholderSite: 'Insira as informações do seu site',

        titleVslSMBPolicyFree: 'VSL SMB - Livre de Políticas',
        titleVslEnterprisePolicyFree: 'VSL Enterprise - Livre de Políticas',
        titleSalesConversationalB2C: 'Conversa de Vendas B2C/SMB',
        titleSalesConversationalEnterprise: 'Conversa de Vendas Enterprise',
        titleSingleMechanism: 'Mecanismo Único',
        titleSlideShow: 'Textos para Slides',
        titleTwentyNames: 'Nomes de Produtos / Domínios / Negócios',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Pontos Críticos da Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Cronologia da Transformação',
        titleGAVideoAdsWhistleblower: 'Controlador de Denúncias Corporativas',
        titleGAVideoAdsPermissionSkip: 'Permissão de Ignorar',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Imprevista',
        titleGAVideoAdsMithBuster: 'Desmistificação',
        titleGAVideoAdsWeirdHack: 'Violação de Segurança',

        optionSellingMode: 'Modo de Venda',
        optionFriendly: 'Amigável',
        optionProfessional: 'Profissional',
        optionExpert: 'Especialista',
        optionBusinessAndTrust: 'Negócios e Confiança',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicação Sensível',

        btnAddAll: 'Adicionar todos',
        btnDeleteAll: 'Apagar todos',
        btnExplore: 'Explorar templates',
        language,
      };

    case 'es-ES':
      return {
        ...terms,
        title: 'CopyCut',
        messageProccessInfo: 'Estamos organizando tu información. Esto puede tardar hasta 90 segundos. Por favor, no abandones esta pantalla.',
        messageProccessInfoNoSec: 'Estamos organizando tu información. Por favor, no abandones esta pantalla.',

        btnOne: 'Crear VSL Copy - B2C/SMB y Enterprise',

        h4TitleVslCopy: 'VSL Copy - B2C/SMB y Enterprise',
        h4TitleVslPolicyFree: 'VSL Libre de Política - B2C/SMB y Enterprise',
        h4TitleAssets: 'Assets Copy - Marketing y Ventas',
        h4TitleEngineValidation: 'Engine Validation - Order Bump y Upsell',
        h4TitleScaleCopy: 'Scale Copy - Copias de Activos de Marketing para Equipos de Ventas y Marketing',
        h4TitleGAKeywords: 'Google Ads - Palabras clave',
        h4TitleGAAnnouncementGroup: 'Google Ads - Grupos de anuncios',
        h4TitleGAAnnouncement: 'Google Ads - Campañas y Grupos de anuncios',
        h4TitleMetaAds: 'Meta Ads - Presupuestos, Campañas, Intereses, Anuncios y Optimización',
        h4TitleTiktokAds: 'TikTok Ads - Formatos de Video, Elementos Virales, Estrategias de Publicación y Elementos de Participación',
        h4TitleLinkedinAds: 'LinkedIn Ads - Segmentación, Campañas, Anuncios y Optimización',
        h4TitleVideoAds: 'Video Ads - Guiones Estratégicos para Marketing Digital',

        questionModel: '¿Tu negocio es B2C o B2B?',
        questionLanguage: '¿Cuál es el idioma de las respuestas?',
        questionToneOfVoice: 'Tono de Voz',
        questionAvatar: '¿Cuál es tu cliente perfecto? (ICP / Audiencia / Persona / AVATAR)',
        questionNiche: '¿Cuál es tu nicho de mercado? (Nicho o mercado masivo)',
        questionSite: '¿Datos del sitio web / Landing Page / Competidores / Datos de correo electrónico?',

        placeholderAvatar: 'Ingresa la información de tu avatar',
        placeholderNiche: 'Ingresa la información de tu nicho',
        placeholderSite: 'Ingresa la información de tu sitio web',

        titleVslSMBPolicyFree: 'VSL SMB - Libre de Políticas',
        titleVslEnterprisePolicyFree: 'VSL Enterprise - Libre de Políticas',
        titleSalesConversationalB2C: 'Conversación de Ventas B2C/SMB',
        titleSalesConversationalEnterprise: 'Conversación de Ventas Enterprise',
        titleSingleMechanism: 'Mecanismo Único',
        titleSlideShow: 'Presentación de diapositivas',
        titleTwentyNames: 'Nombres de Productos / Dominios / Negocios',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Puntos de Dolor de la Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Cronología de la Transformación',
        titleGAVideoAdsWhistleblower: 'Controlador de Denuncias Internas',
        titleGAVideoAdsPermissionSkip: 'Permiso de Omisión',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Inesperada',
        titleGAVideoAdsMithBuster: 'Desmitificador',
        titleGAVideoAdsWeirdHack: 'Vulnerabilidad de Seguridad',

        optionSellingMode: 'Modo Venta',
        optionFriendly: 'Amigable',
        optionProfessional: 'Profesional',
        optionExpert: 'Especialista',
        optionBusinessAndTrust: 'Negocios y Confianza',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicación Sensible',

        btnAddAll: 'Agregar todos',
        btnDeleteAll: 'Eliminar todos',
        btnExplore: 'Explorar plantillas',
        language,
      };

    default:
      return terms;
  }
}
