export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  const terms = {
    doneBy: 'Created by: ',

    labelBusinessModel: 'Business Model',
    labelNiche: 'Niche',
    labelSite: 'Site / Landing Page data / Competitors',
    labelLanguage: 'Language',
    labelToneOfVoice: 'Tone of Voice',

    modalTitle: 'Are you sure you want to delete this item?',
    modalBtn: 'Delete',

    titleVslSMBPolicyFree: 'VSL SMB (Policy Free)',
    titleVslEnterprisePolicyFree: 'VSL Enterprise (Policy Free)',
    titleSingleMechanism: 'Single Mechanism',
    titleSlideShow: 'Slideshow',
    titleTwentyNames: 'Product Names / Domains / Business',
    titleGAKeywords: 'Google Ads - Keywords',
    titleGAAnnouncementGroup: 'Google Ads - Ad Groups',
    titleGAAnnouncement: 'Google Ads - Campaigns and Ad Groups',
    titleGATiktokAds: 'TikTok Ads - Video Formats, Viral Elements, Posting Strategies and Engagement Elements',
    titleGALinkedinAds: 'LinkedIn Ads - Targeting, Campaigns, Ads and Optimization',
    titleGAVideoAdsHistoricalEvolution: 'Historical Evolution',
    titleGAVideoAdsPersonaPainPoints: 'Persona Pain Points',
    titleGAVideoAdsTransformationTimeLapse: 'Transformation Time Lapse',
    titleGAVideoAdsWhistleblower: 'Whistleblower Control Beater',
    titleGAVideoAdsPermissionSkip: 'Skip Permission',
    titleGAVideoAdsUnexpectedDelivery: 'Unexpected Delivery',
    titleGAVideoAdsMithBuster: 'Myth Buster',
    titleGAVideoAdsWeirdHack: 'Security Breach',

    noData: 'No content in this layer.',

    h4TitleVslCopy: 'VSL Copy - B2C/SMB and Enterprise',
    h4TitleVslPolicyFree: 'VSL Policy Free - B2C/SMB and Enterprise',
    h4TitleEngineValidation: 'Engine Validation - Order Bump and Upsell',
    h4TitleScaleCopy: 'Scale Copy - Marketing Assets Copy for Sales and Marketing Teams',
    h4TitleVideoAds: 'Video Ads - Strategic Scripts for Digital Marketing',

    toneOfVoiceSaleMode: 'Selling Mode',
    toneOfVoiceProfissional: 'Professional',
    toneOfVoiceExpert: 'Expert',
    toneOfVoiceBusinessAndTrust: 'Business and Trust',
    toneOfVoiceSensitiveCommunication: 'Sensitive Communication',
    toneOfVoiceFriendly: 'Friendly',

    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        doneBy: 'Criado por: ',

        labelBusinessModel: 'Modelo de Negócio',
        labelNiche: 'Nicho',
        labelSite: 'Dados do site / Landing Page / Concorrentes',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tom de voz',

        modalTitle: 'Tem certeza que deseja deletar esse item?',
        modalBtn: 'Deletar',

        titleVslSMBPolicyFree: 'VSL SMB (Livre de Políticas)',
        titleVslEnterprisePolicyFree: 'VSL Enterprise (Livre de Políticas)',
        titleSingleMechanism: 'Mecanismo Único',
        titleSlideShow: 'Textos para Slides',
        titleTwentyNames: 'Nomes de Produtos / Domínios / Negócios',
        titleGAKeywords: 'Google Ads - Palavras-chave',
        titleGAAnnouncementGroup: 'Google Ads - Grupos de anúncio',
        titleGAAnnouncement: 'Google Ads - Campanhas e Grupos de anúncio',
        titleGAMetaAds: 'Meta Ads - Orçamentos, Campanhas, Interesses, Anúncios e Otimização',
        titleGATiktokAds: 'TikTok Ads - Formatos de Vídeo, Elementos Virais, Estratégias de Postagem e Elementos de Engajamento',
        titleGALinkedinAds: 'LinkedIn Ads - Segmentação, Campanhas, Anúncios e Otimização',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Pontos de Dor da Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Linha do Tempo da Transformação',
        titleGAVideoAdsWhistleblower: 'Controlador de Denúncias Internas',
        titleGAVideoAdsPermissionSkip: 'Permissão para Pular',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Inesperada',
        titleGAVideoAdsMithBuster: 'Desmistificador',
        titleGAVideoAdsWeirdHack: 'Violação de Segurança',

        noData: 'Sem conteúdo nesta camada.',

        h4TitleVslCopy: 'VSL Copy - B2C/SMB e Enterprise',
        h4TitleVslPolicyFree: 'VSL Livre de Políticas - B2C/SMB e Enterprise',
        h4TitleEngineValidation: 'Engine Validation - Order Bump e Upsell',
        h4TitleScaleCopy: 'Scale Copy - Marketing Assets Copy para Times de Vendas e Marketing',
        h4TitleVideoAds: 'Video Ads - Roteiros Estratégicos para Marketing Digital',

        toneOfVoiceSaleMode: 'Modo venda',
        toneOfVoiceProfissional: 'Profissional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negócios e Confiança',
        toneOfVoiceSensitiveCommunication: 'Comunicação Sensível',
        toneOfVoiceFriendly: 'Amigável',

        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        doneBy: 'Criado por: ',

        labelBusinessModel: 'Modelo de Negócio',
        labelNiche: 'Nicho',
        labelSite: 'Dados do site / Landing Page / Concorrentes',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tom de voz',

        modalTitle: 'Tem a certeza que pretende eliminar este item?',
        modalBtn: 'Eliminar',

        titleVslSMBPolicyFree: 'VSL SMB (Livre de Políticas)',
        titleVslEnterprisePolicyFree: 'VSL Enterprise (Livre de Políticas)',
        titleSingleMechanism: 'Mecanismo Único',
        titleSlideShow: 'Textos para Slides',
        titleTwentyNames: 'Nomes de Produtos / Domínios / Negócios',
        titleGAKeywords: 'Google Ads - Palavras-chave',
        titleGAAnnouncementGroup: 'Google Ads - Grupos de anúncios',
        titleGAAnnouncement: 'Google Ads - Campanhas e Grupos de anúncio',
        titleGAMetaAds: 'Meta Ads - Orçamentos, Campanhas, Interesses, Anúncios e Otimização',
        titleGATiktokAds: 'TikTok Ads - Formatos de Vídeo, Elementos Virais, Estratégias de Publicação e Elementos de Envolvimento',
        titleGALinkedinAds: 'LinkedIn Ads - Segmentação, Campanhas, Anúncios e Otimização',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Pontos Críticos da Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Cronologia da Transformação',
        titleGAVideoAdsWhistleblower: 'Controlador de Denúncias Corporativas',
        titleGAVideoAdsPermissionSkip: 'Permissão de Ignorar',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Imprevista',
        titleGAVideoAdsMithBuster: 'Desmistificação',
        titleGAVideoAdsWeirdHack: 'Violação de Segurança',

        noData: 'Sem conteúdo nesta camada.',

        h4TitleVslCopy: 'VSL Copy - B2C/SMB e Enterprise',
        h4TitleVslPolicyFree: 'VSL Livre de Políticas - B2C/SMB e Enterprise',
        h4TitleEngineValidation: 'Engine Validation - Order Bump e Upsell',
        h4TitleScaleCopy: 'Scale Copy - Cópia de Ativos de Marketing para Equipas de Vendas e Marketing',
        h4TitleVideoAds: 'Video Ads - Guiões Estratégicos para Marketing Digital',

        toneOfVoiceSaleMode: 'Modo venda',
        toneOfVoiceProfissional: 'Profissional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negócios e Confiança',
        toneOfVoiceSensitiveCommunication: 'Comunicação Sensível',
        toneOfVoiceFriendly: 'Amigável',

        language,
      };

    case 'es-ES':
      return {
        ...terms,
        doneBy: 'Creado por: ',

        labelBusinessModel: 'Modelo de Negocio',
        labelNiche: 'Nicho',
        labelSite: 'Datos del sitio / Landing Page / Competidores',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tono de voz',

        modalTitle: '¿Estás seguro de que quieres eliminar este elemento?',
        modalBtn: 'Eliminar',

        titleVslSMBPolicyFree: 'VSL SMB (Libre de Políticas)',
        titleVslEnterprisePolicyFree: 'VSL Enterprise (Libre de Políticas)',
        titleSingleMechanism: 'Mecanismo Único',
        titleSlideShow: 'Presentación de diapositivas',
        titleTwentyNames: 'Nombres de Productos / Dominios / Negocios',
        titleGAKeywords: 'Google Ads - Palabras clave',
        titleGAAnnouncementGroup: 'Google Ads - Grupos de anuncios',
        titleGAAnnouncement: 'Google Ads - Campañas y Grupos de anuncios',
        titleGAMetaAds: 'Meta Ads - Presupuestos, Campañas, Intereses, Anuncios y Optimización',
        titleGATiktokAds: 'TikTok Ads - Formatos de Video, Elementos Virales, Estrategias de Publicación y Elementos de Participación',
        titleGALinkedinAds: 'LinkedIn Ads - Segmentación, Campañas, Anuncios y Optimización',
        titleGAVideoAdsHistoricalEvolution: 'Evolução Histórica',
        titleGAVideoAdsPersonaPainPoints: 'Puntos de Dolor de la Persona',
        titleGAVideoAdsTransformationTimeLapse: 'Cronología de la Transformación',
        titleGAVideoAdsWhistleblower: 'Controlador de Denuncias Internas',
        titleGAVideoAdsPermissionSkip: 'Permiso de Omisión',
        titleGAVideoAdsUnexpectedDelivery: 'Entrega Inesperada',
        titleGAVideoAdsMithBuster: 'Desmitificador',
        titleGAVideoAdsWeirdHack: 'Vulnerabilidad de Seguridad',

        noData: 'Sin contenido en esta capa.',

        h4TitleVslCopy: 'VSL Copy - B2C/SMB y Enterprise',
        h4TitleVslPolicyFree: 'VSL Libre de Políticas - B2C/SMB y Enterprise',
        h4TitleEngineValidation: 'Engine Validation - Order Bump y Upsell',
        h4TitleScaleCopy: 'Scale Copy - Copias de Activos de Marketing para Equipos de Ventas y Marketing',
        h4TitleVideoAds: 'Video Ads - Guiones Estratégicos para Marketing Digital',

        toneOfVoiceSaleMode: 'Modo de venta',
        toneOfVoiceProfissional: 'Profesional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negocios y Confianza',
        toneOfVoiceSensitiveCommunication: 'Comunicación Sensible',
        toneOfVoiceFriendly: 'Amigable',

        language,
      };

    default:
      return terms;
  }
}
