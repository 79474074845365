import React from 'react';
import { Link } from 'react-router-dom';
import IconShiftersYellow from '../../styles/shifters-logos/shifters_yellow.png';
import BackButton from '../../components/BackButton';
import { Container } from './style';

export function LegalPageTemplate({ children }) {
  return (
    <Container>
      <div className="center-container">
        <Link to="/">
          <img src={IconShiftersYellow} alt="ShiftesLogo" />
        </Link>
      </div>

      {children}

      <div className="center-container">
        <BackButton />
      </div>
    </Container>
  );
}
