import { LegalPageTemplate } from '../LegalPageTemplate';
import { PrivacyPolicyContent } from '../LegalPageTemplate/PrivacyPolicyContent';

export function PrivacyPolicy() {
  return (
    <>
      <LegalPageTemplate>
        <PrivacyPolicyContent />
      </LegalPageTemplate>
    </>
  );
}
