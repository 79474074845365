import styled from 'styled-components';

export const Text = styled.div`
  font-size: 12px;
  text-align: center;
  position: absolute;
  bottom: 16px;
  left: 80px;
  right: 80px;
  line-height: 100%;
  font-weight: 100;

  * {
    color: ${(props) => props.theme.grayLight};
  }

  .container-terms {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 4px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.smartphone}){
    display: none;
  }
`;

export const Site = styled.a`
  color: ${(props) => props.theme.yellow};
  text-decoration: none;
`;
