import styled, { css } from 'styled-components';

export const Badge = styled.span`
  font-size: ${({ size }) => (size === 'l' ? '16px' : '12px')};
  background-color: ${({ theme, color }) => (color || theme.grayDark)};
  color: ${({ theme, color }) => (color ? theme.grayBackground : theme.fontWhite)};
  padding: ${({ size }) => (size === 'l' ? '4px 16px' : '4px 8px')};
  border-radius: 4px;

  &:hover {
    background-color: rgba(101, 102, 105, 0.7);
  }

  ${({ color, theme }) => color === 'green' && css`
    background-color: ${theme.green};
    color: ${({ theme }) => theme.grayMenu};

    * {
      background-color: ${theme.green};
      color: ${({ theme }) => theme.grayMenu};
    }
  `}

  ${({ color, theme }) => color === 'blue' && css`
    background-color: ${theme.blue};
    color: ${({ theme }) => theme.grayMenu};

    * {
      background-color: ${theme.blue};
      color: ${({ theme }) => theme.grayMenu};
    }
  `}

  ${({ color, theme }) => color === 'red' && css`
    background-color: ${theme.red};
    color: ${({ theme }) => theme.fontWhite};

    * {
      background-color: ${theme.red};
      color: ${({ theme }) => theme.fontWhite};
    }
  `}

  ${({ color, theme }) => color === 'yellow' && css`
    background-color: ${theme.yellow};
    color: ${({ theme }) => theme.grayMenu};

    * {
      background-color: ${theme.yellow};
      color: ${({ theme }) => theme.grayMenu};
    }
  `}

  ${({ color, theme }) => color === 'gray' && css`
    background-color: ${theme.gray5};
    color: ${({ theme }) => theme.grayMenu};

    * {
      background-color: ${theme.gray5};
      color: ${({ theme }) => theme.grayMenu};
    }

    &:hover {
      color: ${({ theme }) => theme.fontWhite};
    }
  `}

  strong {
    font-weight: bolder;
  }


`;
