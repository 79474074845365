import React from 'react';
import TitlePage from '../../../../components/TitlePage';
import { Translate } from './translate';
import { CardContainer, Card } from './style';

export function IndexAILab() {
  const translate = Translate();
  return (
    <>
      <TitlePage title="AILab Hub" />
      <CardContainer>
        <Card to="/client/ailab/copycut">
          <div className="tool-name">CopyCut</div>
          <div className="description">{translate.txtCopyCut}</div>
        </Card>
        <Card className="soon">
          <div className="tool-name">TranslateAI</div>
          <div className="description">{translate.txtTranslateAI}</div>
          <div className="badge">{translate.badge}</div>
        </Card>
      </CardContainer>
    </>
  );
}

