import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './styles';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <S.Text>
      <p>
        Copyright ©
        {currentYear}
        {' '}
        -
        {' '}
        <S.Site href="https://www.shifters.ai/" target="_blank"> www.shifters.com.br</S.Site>
        {' '}
        - Todos os Direitos Reservados.
      </p>
      <p>
        SHIFTERS - CONSULTORIA E SOFTWARE LTDA - EPP - CNPJ: 15.563.090/0001-04 - Rua Morato Coelho. 957 - Pinheiros - 05417011 - São Paulo / SP
      </p>
      <div className="container-terms">
        <Link to="/privacy-policy">Política de Privacidade</Link>
        <Link to="/terms-and-conditions">Termos e Condições</Link>
      </div>
    </S.Text>
  );
}

export default Footer;
