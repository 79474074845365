import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { ManageProjectContext } from '../../manageProjects.context';
import { AuthContext } from '../../../../../../context/AuthContext';

import Loader from '../../../../../../components/Loader';
import { Button } from '../../../../../../components/Button';
import Modal from '../../../../../../components/Modal';

import IconClipboard from '../../../../../../styles/logos/clipboard.svg';
import IconCheck from '../../../../../../styles/logos/check_icon.svg';
import IconTrash from '../../../../../../styles/logos/icon_trash_white.svg';
import IconEdit from '../../../../../../styles/logos/icon_edit_pencil.svg';

import { Translate } from './translate';
import {
  Container, TitleContainer, EntranceContainer, ResponseContainer, ResponseCardsContainer, Card,
} from './style';

export function ProjectInfos() {
  const translate = Translate();
  const { id } = useParams();
  const {
    setProjectID, projectInfos, isLoadingProjectInfo, toogleModal, handleToogleModal, handleDeleteProject,
  } = useContext(ManageProjectContext);
  const { userInfo } = useContext(AuthContext);

  const [visibleCards, setVisibleCards] = useState({
    foundationalCopy: false,
    foundationalCopyHumanized: false,
  });

  // CHANGE ID FROM Context function
  useEffect(() => {
    setProjectID(id);
  }, [id]);

  function handleCardsFoundational(containerId, event) {
    if (event.target.tagName === 'IMG') return;
    setVisibleCards((prev) => ({
      ...prev,
      [containerId]: !prev[containerId],
    }));
  }

  function copyContent(event) {
    const element = event.target;
    const infoToCopy = element.parentElement.nextElementSibling.textContent;
    navigator.clipboard.writeText(infoToCopy);
    element.setAttribute('src', IconCheck);

    setTimeout(() => {
      element.setAttribute('src', IconClipboard);
    }, 2000);
  }

  function translateToneOfVoice(toneOfVoice) {
    let toneOfVoiceTranslated = '';

    switch (toneOfVoice) {
      case 'saleMode':
        toneOfVoiceTranslated = translate.toneOfVoiceSaleMode;
        break;

      case 'profissional':
        toneOfVoiceTranslated = translate.toneOfVoiceProfissional;
        break;

      case 'expert':
        toneOfVoiceTranslated = translate.toneOfVoiceExpert;
        break;

      case 'businessAndTrust':
        toneOfVoiceTranslated = translate.toneOfVoiceBusinessAndTrust;
        break;

      case 'sensitiveCommunication':
        toneOfVoiceTranslated = translate.toneOfVoiceSensitiveCommunication;
        break;

      case 'friendly':
        toneOfVoiceTranslated = translate.toneOfVoiceFriendly;
        break;

      default:
        toneOfVoiceTranslated = toneOfVoice.charAt(0).toUpperCase() + toneOfVoice.slice(1);
        break;
    }

    return toneOfVoiceTranslated;
  }

  return (
    <>
      {isLoadingProjectInfo && <Loader loading={isLoadingProjectInfo} />}
      {!isLoadingProjectInfo && (
        <Container>
          <TitleContainer>
            <div className="title-infos">
              <div className="title-area">
                <h2>{projectInfos?.name}</h2>
                <Link to={`/client/ailab/manage-projects/edit-name/${projectInfos.id}?name=${projectInfos.name}`}>
                  <img src={IconEdit} alt="edit" />
                </Link>
              </div>
              <p>{translate.doneBy}<span>{projectInfos?.firstName} {projectInfos?.lastName} | {projectInfos?.email}</span></p>
            </div>
            <div className="title-actions">
              <Button
                color="red"
                onClick={() => handleToogleModal()}
              >
                <img
                  src={IconTrash}
                  alt="Delete Prompt"
                />
              </Button>
            </div>
          </TitleContainer>

          <EntranceContainer>
            <h3>Inputs</h3>
            <div className="info-group-d-flex">
              <div className="info-group">
                <label htmlFor="avatar">{translate.labelBusinessModel}</label>
                <div>{projectInfos?.businessModel ? projectInfos?.businessModel : '---'}</div>
              </div>
              <div className="info-group">
                <label htmlFor="avatar">{translate.labelLanguage}</label>
                <div>{projectInfos?.language ? projectInfos?.language : '---'}</div>
              </div>
              <div className="info-group">
                <label htmlFor="avatar">{translate.labelToneOfVoice}</label>
                <div>{projectInfos?.toneOfVoice ? translateToneOfVoice(projectInfos?.toneOfVoice) : '---'}</div>
              </div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">Avatar</label>
              <div>{projectInfos?.avatar}</div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">{translate.labelNiche}</label>
              <div>{projectInfos?.niche}</div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">{translate.labelSite}</label>
              <div>{projectInfos?.siteData}</div>
            </div>
          </EntranceContainer>

          <ResponseContainer>
            <h3>Outputs</h3>
            <div className="container-painels">

              <div className="container-layers">
                <h4>{translate.h4TitleVslCopy}</h4>
                <ResponseCardsContainer>
                  {/* TODO: Melhorar isso aqui depois */}
                  {userInfo.email === 'suporte+111menu@shifters.com.br' && (
                  <>
                    {(projectInfos?.foundationalCopy && projectInfos?.foundationalCopy.length > 0) && (
                    <Card $visible={visibleCards?.foundationalCopy}>
                      <div className="title" onClick={(e) => handleCardsFoundational('foundationalCopy', e)}>
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {projectInfos?.foundationalCopy}
                      </div>
                    </Card>
                    )}

                    {(projectInfos?.foundationalCopyHumanized && projectInfos?.foundationalCopyHumanized.length > 0) && (
                    <Card $visible={visibleCards?.foundationalCopyHumanized}>
                      <div className="title" onClick={(e) => handleCardsFoundational('foundationalCopyHumanized', e)}>
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {projectInfos?.foundationalCopyHumanized}
                      </div>
                    </Card>
                    )}
                  </>
                  )}

                  {(projectInfos?.vslSmb && projectInfos?.vslSmb.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>VSL SMB</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslSmb}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.vslEnterprise && projectInfos?.vslEnterprise.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>VSL Enterprise</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslEnterprise}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>

              <div className="container-layers">
                <h4>{translate.h4TitleVslPolicyFree}</h4>

                {(!projectInfos?.vslSmbPolicyFree && !projectInfos?.vslEnterprisePolicyFree) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {(projectInfos?.vslSmbPolicyFree && projectInfos?.vslSmbPolicyFree.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleVslSMBPolicyFree}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslSmbPolicyFree}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.vslEnterprisePolicyFree && projectInfos?.vslEnterprisePolicyFree.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleVslEnterprisePolicyFree}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslEnterprisePolicyFree}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>

              <div className="container-layers">
                <h4>ASSETS Copy - Marketing e Vendas</h4>

                {(!projectInfos?.headlines
                  && !projectInfos?.singleMechanism
                  && !projectInfos?.faqs
                  && !projectInfos?.slideshow
                  && !projectInfos?.linkedinOutboundSMB
                  && !projectInfos?.linkedinOutboundEnterprise
                  && !projectInfos?.emailOutboundSMB
                  && !projectInfos?.emailOutboundEnterprise
                  && !projectInfos?.salesLetterSmb
                  && !projectInfos?.salesLetterEnterprise
                  && !projectInfos?.caseStudyB2B
                  && !projectInfos?.productsNames
                ) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {(projectInfos?.headlines && projectInfos?.headlines.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Headlines</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.headlines}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.singleMechanism && projectInfos?.singleMechanism.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleSingleMechanism}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.singleMechanism}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.faqs && projectInfos?.faqs.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>FAQs</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.faqs}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.slideshow && projectInfos?.slideshow.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleSlideShow}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.slideshow}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.linkedinOutboundSMB && projectInfos?.linkedinOutboundSMB.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>LinkedIn Outbound SMB</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.linkedinOutboundSMB}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.linkedinOutboundEnterprise && projectInfos?.linkedinOutboundEnterprise.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>LinkedIn Outbound Enterprise</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.linkedinOutboundEnterprise}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.emailOutboundSMB && projectInfos?.emailOutboundSMB.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>E-mail Outbound SMB</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.emailOutboundSMB}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.emailOutboundEnterprise && projectInfos?.emailOutboundEnterprise.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>E-mail Outbound Enterprise</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.emailOutboundEnterprise}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.salesLetterSmb && projectInfos?.salesLetterSmb.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Comercial SMB</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.salesLetterSmb}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.salesLetterEnterprise && projectInfos?.salesLetterEnterprise.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Comercial Enterprise</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.salesLetterEnterprise}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.caseStudyB2B && projectInfos?.caseStudyB2B.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Case Study B2B</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.caseStudyB2B}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.productsNames && projectInfos?.productsNames.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleTwentyNames}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.productsNames}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>

              <div className="container-layers">
                <h4>{translate.h4TitleEngineValidation}</h4>

                {(!projectInfos?.orderBump && !projectInfos?.upsell) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {(projectInfos?.orderBump && projectInfos?.orderBump.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Order Bump</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.orderBump}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.upsell && projectInfos?.upsell.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Upsell</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.upsell}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>

              <div className="container-layers">
                <h4>{translate.h4TitleScaleCopy}</h4>

                {(!projectInfos?.googleAdsKeywords
                  && !projectInfos?.googleAdsAnnouncementGroup
                  && !projectInfos?.googleAdsAnnouncement
                  && !projectInfos?.metaAds
                  && !projectInfos?.tiktokAds
                  && !projectInfos?.linkedinAds
                ) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {(projectInfos?.googleAdsKeywords && projectInfos?.googleAdsKeywords.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAKeywords}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.googleAdsKeywords}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.googleAdsAnnouncementGroup && projectInfos?.googleAdsAnnouncementGroup.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAAnnouncementGroup}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.googleAdsAnnouncementGroup}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.googleAdsAnnouncement && projectInfos?.googleAdsAnnouncement.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAAnnouncement}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.googleAdsAnnouncement}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.metaAds && projectInfos?.metaAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAMetaAds}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.metaAds}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.tiktokAds && projectInfos?.tiktokAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGATiktokAds}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.tiktokAds}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.linkedinAds && projectInfos?.linkedinAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGALinkedinAds}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.linkedinAds}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>

              <div className="container-layers">
                <h4>{translate.h4TitleVideoAds}</h4>

                {(!projectInfos?.videoAds
                  && !projectInfos?.videoAdsHistoricalEvolution
                  && !projectInfos?.videoAdsPersonaProblem
                  && !projectInfos?.videoAdsTransformationsTimeLapse
                  && !projectInfos?.videoAdsWhistleblowerControl
                  && !projectInfos?.videoAdsPermissionSkip
                  && !projectInfos?.videoAdsUnexpectedDelivery
                  && !projectInfos?.videoAdsMithBuster
                  && !projectInfos?.videoAdsWeirdHack
                ) && (
                  <div className="no-info">{translate.noData}</div>
                )}

                <ResponseCardsContainer>
                  {(projectInfos?.videoAds && projectInfos?.videoAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Video Ads</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.videoAds}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.videoAdsHistoricalEvolution && projectInfos?.videoAdsHistoricalEvolution.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsHistoricalEvolution}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.videoAdsHistoricalEvolution}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.videoAdsPersonaProblem && projectInfos?.videoAdsPersonaProblem.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsPersonaPainPoints}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.videoAdsPersonaProblem}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.videoAdsTransformationsTimeLapse && projectInfos?.videoAdsTransformationsTimeLapse.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsTransformationTimeLapse}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.videoAdsTransformationsTimeLapse}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.videoAdsWhistleblowerControl && projectInfos?.videoAdsWhistleblowerControl.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsWhistleblower}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.videoAdsWhistleblowerControl}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.videoAdsPermissionSkip && projectInfos?.videoAdsPermissionSkip.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsPermissionSkip}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.videoAdsPermissionSkip}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.videoAdsUnexpectedDelivery && projectInfos?.videoAdsUnexpectedDelivery.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsUnexpectedDelivery}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.videoAdsUnexpectedDelivery}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.videoAdsMithBuster && projectInfos?.videoAdsMithBuster.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsMithBuster}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.videoAdsMithBuster}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.videoAdsWeirdHack && projectInfos?.videoAdsWeirdHack.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsWeirdHack}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.videoAdsWeirdHack}
                    </div>
                  </Card>
                  )}

                </ResponseCardsContainer>
              </div>
            </div>
          </ResponseContainer>
        </Container>
      )}
      <Modal
        danger
        visible={toogleModal}
        title={translate.modalTitle}
        btnMessage={translate.modalBtn}
        onAction={() => handleDeleteProject()}
        onClose={() => handleToogleModal()}
      >
        {projectInfos?.name}
      </Modal>
    </>

  );
}
