import { LegalPageTemplate } from '../LegalPageTemplate';
import { TermsContent } from '../LegalPageTemplate/TermsContent';

export function TermsAndCondition() {
  return (
    <>
      <LegalPageTemplate>
        <TermsContent />
      </LegalPageTemplate>
    </>
  );
}
