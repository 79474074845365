import React from 'react';
import { Container } from './style';

export function PrivacyPolicyContent() {
  return (
    <Container>
      <h2>Política de Privacidade</h2>

      <h3>1. Geral</h3>
      <p>A privacidade do usuário é de extrema importância para a SHIFTERS. Com esta declaração queremos que os usuários do nosso site conheçam que tipo de dados recolhemos e como os utilizamos. Esta politica de privacidade aplica-se a qualquer produto da SHIFTERS, incluindo serviços, sites, software ou outros dispositivos.
        A SHIFTERS reserva-se ao direito de, a qualquer momento, alterar a sua politica de privacidade, se comprometendo a não reduzir os direitos do usuário seu consentimento explícito. Quaisquer alterações da política de privacidade serão publicadas neste site e, se as alterações forem significativas, deverá ser enviado um email aos usuários cadastrados.
      </p>

      <h3>2. Coleta de Dados</h3>
      <p>A SHIFTERS recolhe dados para atuar de forma a proporcionar ao usuário as melhores experiências com os nossos produtos. Alguns destes dados são fornecidos diretamente pelo usuário, como, por exemplo quando cria uma conta, compra o SHITER SOFTWARE ou nos contacta para obter suporte.
        A SHIFTERS pode recolher, gravar e utilizar em conexão com outros sites e parceiros as informações pessoais do Usuário, incluindo, mas não se limitando a: nome, marca, foto, endereço, número de telefone, interesses, atividades, sexo e idade. A SHIFTERS se resguarda no direito de gravar seu IP, tipo de navegador, aparelho e operadora de celular, posição geográfica e outros dados solicitados.
        A SHIFTERS não divulga publicamente nem a terceiros suas informações de modo que possam identificá-lo, mas pode fazê-lo de forma agregada no intuito de realizar análises, estudos, melhoria do sistema e por força de ordem judicial.
      </p>

      <h2>Coleta e Uso das Informações</h2>
      <div className="c-2">
        <div>
          <h3>Nós coletamos:</h3>
          <p>
            Seu nome de usuário, endereço de e-mail e preferências de contato.
            Seu nome, imagem e outras informações pessoais, se você optar por compartilhá-las.
            Ações que você executa em sua conta incluindo o uso de certos recursos de produtos e seu
            armazenamento de formas particulares de conteúdo (por exemplo, JPGs), bem como o tipo de dispositivo e software que você está usando.
            Informação de pagamento
          </p>
        </div>
        <div>
          <h3>Porque nós o coletamos:</h3>
          <p>
            Para criar e dar suporte à sua conta no SHIFTERS SOFTWARE. Para nos comunicarmos com você.
            Para exibir em seu perfil de usuário.
            Para administrar o Serviço e melhorar os recursos e a usabilidade do Serviço.
            Para sugerir a você recursos, produtos e serviços que podem melhorar a sua utilização do Serviço.
            Para processar o seu pagamento.
          </p>
        </div>
      </div>

      <h3>3. Porque recolhemos informação</h3>
      <p>A SHIFTERS está comprometida com a salvaguarda da privacidade das informações que possam ser eventualmente enviadas/coletadas nos seus sites, software ou outros dispositivos.
        A coleta de dados no site, no SHIFTERS SOFTWARE ou outros dispositivos de Internet é realizada com os seguintes objetivos:
        <ul>
          <li>Proporcionar melhorias na prestação dos seus serviços e suporte adequado à realização de seu objeto;</li>
          <li>Adequar o conteúdo às preferências do usuário, a fim de proporcionar-lhe acesso mais rápido, agradável e eficaz;</li>
          <li>Autenticar acessos;</li>
          <li>Buscar segurança adequada aos serviços oferecidos e meios de identificação no caso de usos impróprios ou ilícitos;
          </li>
          <li>Resolver problemas e formular notificações relacionadas ao uso do site, SHIFTERS SOFTWARE, plataformas e aplicações de Internet da SHIFTERS;</li>
          <li>Manter os usuários informados sobre os serviços, alterações nos Termos de Uso ou na Política de Privacidade, atualizações ou melhorias no site, SHIFTERS SOFTWARE, plataformas e aplicações de Internet da SHIFTERS ou ainda sobre o status de suas operações com o SHIFTERS SOFTWARE;Divulgar ações promocionais do site, SHIFTERS SOFTWARE, plataformas e aplicações de Internet da SHIFTERS ou outras iniciativas SHIFTERS SOFTWARE sempre disponibilizando meios para o usuário cancelar o recebimento de e-mails promocionais;Divulgar ações promocionais de empresas parceiras que adotem medidas de proteção à privacidade, similares a presente política, mediante anuência prévia do usuário;Apurar informações estatísticas e disponibilizá-las.</li>
        </ul>
      </p>

      <h3>4. Utilização dos dados pessoais</h3>
      <p>A SHIFTERS utiliza os dados recolhidos para disponibilizar os seus produtos, melhorar e personalizar a sua experiência, comunicar com o cliente para, por exemplo, passar informações sobre a sua conta, atualizações de segurança ou do produto e passar informações de produto. Não são utilizadas informações trocadas em e-mail, chat, chamadas de vídeo ou voice mail, bem como documentos, fotografias ou outros ficheiros pessoais.
        A SHIFTERS apenas partilha dados pessoais com o seu consentimento ou se necessário para concluir qualquer transação ou fornecer um determinado produto solicitado ou autorizado.
        Os dados podem ser partilhados com parceiros afiliados e subsidiárias controlados pela SHIFTERS, bem como fornecedores autorizados que trabalhem em nosso nome ou quando exigido por lei ou para responder perante um processo jurídico, para proteger os nossos clientes, para proteger vidas, a segurança dos nossos produtos e os direitos ou a propriedade intelectual da SHIFTERS.
      </p>

      <h3>5. Gerenciamento dos dados pessoais</h3>
      <p>É possível o acesso e controlo dos dados pessoais recolhidos pela SHIFTERS. Os dados pessoas podem ser visualizados, editados ou eliminados, devendo para tal ser enviado um email para suporte@shifters.software
        Também pode optar por receber ou não e-mails promocionais, mensagens SMS ou chamadas telefónicas.
      </p>

      <h3>6. Cookies</h3>
      <p>A SHIFTERS utiliza cookies (pequenos ficheiros de texto colocados no seu dispositivo) e tecnologias semelhantes para fornecer os nossos sites e serviços online, e ajudar a recolher dados. Os cookies permitem-nos, entre outros, armazenar as suas preferências e definições, iniciar sessão, fornecer anúncios baseados em interesses, combater fraudes e analisar o desempenho dos nossos sites e serviços online.
        Tem à sua disposição uma variedade de ferramentas para controlar os cookies e tecnologias semelhantes, incluindo controlos de browser para bloquear e eliminar cookies e controlos de fornecedores de serviços de análise de terceiros para recusar a recolha de dados. O seu browser e outras escolhas podem ter impacto nas suas experiências com os nossos produtos.
      </p>
    </Container>
  );
}
