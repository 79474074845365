/* eslint-disable react/no-array-index-key */
import React, {
  useState, useRef, useEffect, useContext,
} from 'react';

import { AuthContext } from '../../../../context/AuthContext';
import AIPromptServices from '../../../../services/AIPrompt';

import TitlePage from '../../../../components/TitlePage';
import { Button } from '../../../../components/Button';
import toast from '../../../../helpers/toast';

import IconClipboard from '../../../../styles/logos/clipboard.svg';
import IconCheck from '../../../../styles/logos/check_icon.svg';
import IconArrow from '../../../../styles/logos/arrow.svg';
import IconAdd from '../../../../styles/logos/add.svg';
import IconClear from '../../../../styles/logos/icon_clear.svg';

import { nicheList } from './lists/nicheList';
import { avatarList } from './lists/avatarList';
import { LoaderContainerMemo } from './LoaderContainer';

import { Translate } from './translate';
import {
  Container, LoaderContainer, QuestionArea, QuestionAreaTwoColumns, QuestionContainer, ResponseArea, ResponseContainer, Card,
} from './style';
import { ButtonsArea } from './ButtonsArea';

export function CopyCut() {
  const translate = Translate();
  const { userInfo } = useContext(AuthContext);
  const [infos, setInfos] = useState({
    id: null,
    businessModel: 'B2B',
    infoSite: '',
    niche: '',
    avatar: '',
    language: translate.language === 'pt-BR' ? 'pt-BR' : 'en-US',
    toneOfVoice: 'saleMode',
  });
  const [promptsResponse, setPromptsResponse] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [buttonsArea, setButtonsArea] = useState({
    show: false,
    buttons: {
      second: true,
      third: true,
      fourth: true,
      fifth: true,
      sixth: true,
    },
  });
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [colletedDataFirstStage, setCollectedDataFirstStage] = useState(false);
  const [colletedDataSecondStage, setCollectedDataSecondStage] = useState(false);
  const [colletedDataThirdStage, setCollectedDataThirdStage] = useState(false);
  const [colletedDataFourthStage, setCollectedDataFourthStage] = useState(false);
  const [colletedDataFifthStage, setCollectedDataFifthStage] = useState(false);
  const [colletedDataSixthStage, setCollectedDataSixthStage] = useState(false);

  const [countLoader, setCountLoader] = useState(0);
  const intervalLoader = useRef(null);

  const [optionsAvatar, setOptionsAvatar] = useState(avatarList(infos.businessModel, translate.language));
  const [optionsNiche, setOptionsNiche] = useState(nicheList(infos.businessModel, translate.language));

  const [visibleOptions, setVisibleOptions] = useState({
    avatar: false,
    niche: false,
  });

  const [visibleCards, setVisibleCards] = useState({
    foundationalCopy: false,
    foundationalCopyHumanized: false,
  });

  const divOptionsAvatar = useRef(null);
  const divOptionsNiche = useRef(null);

  // CLEAR ALL EVENTS OF THE PAGE
  useEffect(() => () => {
    clearInterval(intervalLoader.current);
  }, []);

  // MONITORS BTN TO ENABLED AND DISBLED
  useEffect(() => {
    toogleMainBtn();
  }, [infos]);

  // CHANGE AVATARS AND NICHE LISTS AS CHANGE BUSINESS MODEL USESATE AND CLEAR INFOS
  useEffect(() => {
    setOptionsAvatar(avatarList(infos.businessModel, translate.language));
    setOptionsNiche(nicheList(infos.businessModel, translate.language));
    setInfos((prev) => ({ ...prev, avatar: '', niche: '' }));
    setVisibleOptions({
      avatar: false,
      niche: false,
    });
  }, [infos.businessModel]);

  async function getDataFirstStage() {
    // Clear values
    setIsLoadingData(true);
    setPromptsResponse({});

    // Init counter
    intervalLoader.current = setInterval(() => {
      setCountLoader((prevCount) => Number(prevCount) + 0.5);
    }, 1000);

    // Get datas
    try {
      const responsePromptsDb = await AIPromptServices.postPrompt({ infos });
      setPromptsResponse((prev) => ({
        ...prev,
        ...responsePromptsDb,
        id: responsePromptsDb.id,
      }));

      setInfos({
        infoSite: '',
        niche: '',
        avatar: '',
      });

      // Toogle conditions of buttons and datas
      setButtonsArea((prevState) => ({ ...prevState, show: true }));
      setCollectedDataFirstStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('first-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataSecondStage() {
    // Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    intervalLoader.current = setInterval(() => {
      setCountLoader((prevCount) => Number(prevCount) + 0.5);
    }, 1000);

    // Get datas
    try {
      const promptResponseDb = await AIPromptServices.postPrompt2({
        id: promptsResponse.id,
      });

      setPromptsResponse((prev) => ({
        ...prev,
        vslSMBPolicyFree: promptResponseDb.vslSMBPolicyFree,
        vslEnterprisePolicyFree: promptResponseDb.vslEnterprisePolicyFree,
      }));

      // Toogle conditions of buttons and datas
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, second: false } }));
      setCollectedDataSecondStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('second-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataThirdStage() {
    // Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    intervalLoader.current = setInterval(() => {
      setCountLoader((prevCount) => Number(prevCount) + 0.5);
    }, 1000);

    // Get datas
    try {
      const promptResponseDb = await AIPromptServices.postPrompt3({
        id: promptsResponse.id,
      });

      setPromptsResponse((prev) => ({
        ...prev,
        salesLetterSMB: promptResponseDb.comercialSMB,
        salesLetterEnterprise: promptResponseDb.comercialEnterprise,
        headlines: promptResponseDb.headlines,
        singleMechanism: promptResponseDb.singleMechanism,
        caseStudyB2B: promptResponseDb.caseStudyB2B,
        faqs: promptResponseDb.faqs,
        slideshow: promptResponseDb.slideshow,
        linkedinOutboundSMB: promptResponseDb.linkedinOutboundSMB,
        linkedinOutboundEnterprise: promptResponseDb.linkedinOutboundEnterprise,
        emailOutboundSMB: promptResponseDb.emailOutboundSMB,
        emailOutboundEnterprise: promptResponseDb.emailOutboundEnterprise,
        productsNames: promptResponseDb.productsNames,
      }));

      // Btns Actions
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, third: false } }));
      setCollectedDataThirdStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('third-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataFourthStage() {
    // Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    intervalLoader.current = setInterval(() => {
      setCountLoader((prevCount) => Number(prevCount) + 0.5);
    }, 1000);

    // Get datas
    try {
      const promptResponseDb = await AIPromptServices.postPrompt4({
        id: promptsResponse.id,
      });

      setPromptsResponse((prev) => ({
        ...prev,
        orderBump: promptResponseDb.orderBump,
        upsell: promptResponseDb.upsell,
      }));

      // Btns Actions
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, fourth: false } }));
      setCollectedDataFourthStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('fourth-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataFifthStage() {
    // Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    intervalLoader.current = setInterval(() => {
      setCountLoader((prevCount) => Number(prevCount) + 0.5);
    }, 1000);

    // Get datas
    try {
      const promptResponseDb = await AIPromptServices.postPrompt5({
        id: promptsResponse.id,
      });

      setPromptsResponse((prev) => ({
        ...prev,
        googleAdsKeywords: promptResponseDb.googleAdsKeywords,
        googleAdsAnnouncementGroup: promptResponseDb.googleAdsAnnouncementGroup,
        googleAdsAnnouncement: promptResponseDb.googleAdsAnnouncement,
        metaAds: promptResponseDb.metaAds,
        tiktokAds: promptResponseDb.tiktokAds,
        linkedinAds: promptResponseDb.linkedinAds,
      }));

      // Btns Actions
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, fifth: false } }));
      setCollectedDataFifthStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('fifth-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  async function getDataSixthStage() {
    //  Clear values
    setIsLoadingData(true);
    setButtonsArea((prevState) => ({ ...prevState, show: false }));

    // Init counter
    intervalLoader.current = setInterval(() => {
      setCountLoader((prevCount) => Number(prevCount) + 0.5);
    }, 1000);

    // Get datas
    try {
      const promptResponseDb = await AIPromptServices.postPrompt6({
        id: promptsResponse.id,
      });

      setPromptsResponse((prev) => ({
        ...prev,
        videoAds: promptResponseDb.videoAds,
        videoAdsHistoricalEvolution: promptResponseDb.videoAdsHistoricalEvolution,
        videoAdsPermissionSkip: promptResponseDb.videoAdsPermissionSkip,
        videoAdsPersonaProblem: promptResponseDb.videoAdsPersonaProblem,
        videoAdsTransformationsTimeLapse: promptResponseDb.videoAdsTransformationTimeLapse,
        videoAdsUnexpectedDelivery: promptResponseDb.videoAdsUnexpectedDelivery,
        videoAdsMithBuster: promptResponseDb.videoAdsMithBuster,
        videoAdsWhistleblowerControl: promptResponseDb.videoAdsWhistleblowerControl,
        videoAdsWeirdHack: promptResponseDb.videoAdsWeirdHack,
      }));

      // Btns Actions
      setButtonsArea((prevState) => ({ show: true, buttons: { ...prevState.buttons, sixth: false } }));
      setCollectedDataSixthStage(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('sixth-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
      setCountLoader(0);
      clearInterval(intervalLoader.current);
    }
  }

  function copyContent(event) {
    const element = event.target;
    const infoToCopy = element.parentElement.nextElementSibling.textContent;
    navigator.clipboard.writeText(infoToCopy);
    element.setAttribute('src', IconCheck);

    setTimeout(() => {
      element.setAttribute('src', IconClipboard);
    }, 2000);
  }

  function toogleMainBtn() {
    const fieldsFilled = infos.infoSite.length > 0 && infos.niche.length > 0 && infos.avatar.length > 0;
    if (fieldsFilled) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }

  function editFormValues(event) {
    setInfos((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }

  function handleOptionsArea(containerId) {
    setVisibleOptions((prev) => ({
      ...prev,
      [containerId]: !prev[containerId],
    }));
  }

  function handleCardsFoundational(containerId, event) {
    if (event.target.tagName === 'IMG') return;
    setVisibleCards((prev) => ({
      ...prev,
      [containerId]: !prev[containerId],
    }));
  }

  function toogleSelectedOptions(event, type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      const checked = event.target.checked;
      const value = event.target.value;

      if (checked) {
        // ADD INTO STRING
        const stringTurnedIntoArray = infos[key].length === 0 ? [] : infos[key].split(', ');
        if (stringTurnedIntoArray?.includes(value)) return;
        stringTurnedIntoArray.push(value);
        setInfos((prev) => ({ ...prev, [key]: stringTurnedIntoArray.join(', ') }));
      } else {
        // REMOVE FROM STRING
        const stringTurnedIntoArray = infos[key].length === 0 ? [] : infos[key].split(', ');
        const filteredArray = stringTurnedIntoArray.filter((item) => item !== value);
        setInfos((prev) => ({ ...prev, [key]: filteredArray.join(', ') }));
      }
    }
  }

  function addAllOptions(type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      const arrOfOptions = type === 'avatar' ? optionsAvatar : optionsNiche;
      setInfos((prev) => ({ ...prev, [key]: arrOfOptions.join(', ') }));

      const optionsDiv = key === 'avatar' ? divOptionsAvatar.current : divOptionsNiche.current;

      for (let i = 0; i < optionsDiv.children.length; i++) {
        const input = optionsDiv.children[i].children[0];
        input.checked = true;
      }
    }
  }

  function deleteAllOptions(type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      setInfos((prev) => ({ ...prev, [key]: '' }));

      const optionsDiv = key === 'avatar' ? divOptionsAvatar.current : divOptionsNiche.current;

      for (let i = 0; i < optionsDiv.children.length; i++) {
        const input = optionsDiv.children[i].children[0];
        input.checked = false;
      }
    }
  }

  return (
    <>
      <TitlePage title={translate.title} />
      <Container>

        <QuestionArea>
          <QuestionAreaTwoColumns>
            <QuestionContainer>
              <label htmlFor="business-model">{translate.questionModel}</label>
              <div className="radio-area">
                <label>
                  <input
                    type="radio"
                    name="businessModel"
                    value="B2B"
                    onChange={(e) => editFormValues(e)}
                    defaultChecked
                  />
                  B2B
                </label>
                <label>
                  <input
                    type="radio"
                    name="businessModel"
                    value="B2C"
                    onChange={(e) => editFormValues(e)}
                  />
                  B2C
                </label>
              </div>
            </QuestionContainer>
            <div className="container-selects">
              <QuestionContainer>
                <label htmlFor="language">{translate.questionLanguage}</label>
                <select
                  name="language"
                  id="language"
                  value={infos.language}
                  onChange={(e) => editFormValues(e)}
                >
                  {/* <!-- Portuguese --> */}
                  <option value="pt-BR">Português (Brazil)</option>
                  <option value="pt-PT">Português (Portugal)</option>
                  <option value="pt-AO">Português (Angola)</option>
                  <option value="pt-MZ">Português (Mozambique)</option>
                  <option value="pt">Português (Generic)</option>

                  {/* <!-- English --> */}
                  <option value="en-US">English (United States)</option>
                  <option value="en-GB">English (United Kingdom)</option>
                  <option value="en-AU">English (Australia)</option>
                  <option value="en-CA">English (Canada)</option>
                  <option value="en-NZ">English (New Zealand)</option>
                  <option value="en-IE">English (Ireland)</option>
                  <option value="en">English (Generic)</option>

                  {/* <!-- Spanish --> */}
                  <option value="es-ES">Español (Spain)</option>
                  <option value="es-MX">Español (Mexico)</option>
                  <option value="es-AR">Español (Argentina)</option>
                  <option value="es-CL">Español (Chile)</option>
                  <option value="es-CO">Español (Colombia)</option>
                  <option value="es-PE">Español (Peru)</option>
                  <option value="es-VE">Español (Venezuela)</option>
                  <option value="es">Español (Generic)</option>

                  {/* <!-- French --> */}
                  <option value="fr-FR">Français (France)</option>
                  <option value="fr-CA">Français (Canada)</option>
                  <option value="fr-BE">Français (Belgium)</option>
                  <option value="fr-CH">Français (Switzerland)</option>
                  <option value="fr">Français (Generic)</option>

                  {/* <!-- German --> */}
                  <option value="de-DE">Deutsch (Germany)</option>
                  <option value="de-AT">Deutsch (Austria)</option>
                  <option value="de-CH">Deutsch (Switzerland)</option>
                  <option value="de">Deutsch (Generic)</option>

                  {/* <!-- Chinese --> */}
                  <option value="zh-CN">中文 - 简体 (Simplified Chinese)</option>
                  <option value="zh-TW">中文 - 繁體 (Traditional Chinese)</option>
                  <option value="zh-HK">中文 - 香港 (Hong Kong)</option>
                  <option value="zh">中文 (Generic)</option>

                  {/* <!-- Hindi --> */}
                  <option value="hi-IN">हिंदी (India)</option>
                  <option value="hi">हिंदी (Generic)</option>

                  {/* <!-- Bengali --> */}
                  <option value="bn-BD">বাংলা (Bangladesh)</option>
                  <option value="bn-IN">বাংলা (India)</option>
                  <option value="bn">বাংলা (Generic)</option>

                  {/* <!-- Arabic --> */}
                  <option value="ar-SA">العربية (Saudi Arabia)</option>
                  <option value="ar-EG">العربية (Egypt)</option>
                  <option value="ar-DZ">العربية (Algeria)</option>
                  <option value="ar-MA">العربية (Morocco)</option>
                  <option value="ar-TN">العربية (Tunisia)</option>
                  <option value="ar-IQ">العربية (Iraq)</option>
                  <option value="ar-SY">العربية (Syria)</option>
                  <option value="ar-LB">العربية (Lebanon)</option>
                  <option value="ar-AE">العربية (United Arab Emirates)</option>
                  <option value="ar-KW">العربية (Kuwait)</option>
                  <option value="ar-QA">العربية (Qatar)</option>
                  <option value="ar-BH">العربية (Bahrain)</option>
                  <option value="ar-OM">العربية (Oman)</option>
                  <option value="ar-YE">العربية (Yemen)</option>
                  <option value="ar-JO">العربية (Jordan)</option>
                  <option value="ar">العربية (Generic)</option>
                </select>
              </QuestionContainer>
              <QuestionContainer>
                <label htmlFor="toneOfVoice">{translate.questionToneOfVoice}</label>
                <select
                  name="toneOfVoice"
                  value={infos.toneOfVoice}
                  onChange={(e) => editFormValues(e)}
                >
                  <option value="saleMode">{translate.optionSellingMode} ⭐</option>
                  <option value="businessAndTrust">{translate.optionBusinessAndTrust} ⭐</option>
                  <option value="profissional">{translate.optionProfessional} ⭐</option>
                  <option value="formal">Formal</option>
                  <option value="expert">{translate.optionExpert}</option>
                  <option value="casual">Casual</option>
                  <option value="socialMedia">{translate.optionSocialMedia}</option>
                  <option value="sensitiveCommunication">{translate.optionSensitiveCommunication}</option>
                  <option value="friendly">{translate.optionFriendly}</option>
                </select>
              </QuestionContainer>
            </div>
          </QuestionAreaTwoColumns>
          <QuestionContainer $open={visibleOptions.avatar}>
            <label htmlFor="avatar">{translate.questionAvatar}</label>
            <textarea
              name="avatar"
              value={infos.avatar}
              onChange={(e) => editFormValues(e)}
              placeholder={translate.placeholderAvatar}
            />
            <div className="btns-area">
              <div
                onClick={() => handleOptionsArea('avatar')}
                className="btns-action open-modal"
              >
                {translate.btnExplore}
                <img src={IconArrow} alt="Arrow" />
              </div>
              <div
                onClick={() => addAllOptions('avatar')}
                className="btns-action"
              >
                {translate.btnAddAll}
                <img src={IconAdd} alt="Add" />
              </div>
              <div
                onClick={() => deleteAllOptions('avatar')}
                className="btns-action"
              >
                {translate.btnDeleteAll}
                <img src={IconClear} alt="Delete all" />
              </div>
            </div>
            <div className="options-area" ref={divOptionsAvatar}>
              {optionsAvatar.map((item) => (
                <label key={item}>
                  <input
                    type="checkbox"
                    name={item}
                    value={item}
                    onChange={(e) => toogleSelectedOptions(e, 'avatar')}
                  /> {item}
                </label>
              ))}
            </div>
          </QuestionContainer>
          <QuestionContainer $open={visibleOptions.niche}>
            <label htmlFor="niche">{translate.questionNiche}</label>
            <textarea
              name="niche"
              value={infos.niche}
              onChange={(e) => editFormValues(e)}
              placeholder={translate.placeholderNiche}
            />
            <div className="btns-area">
              <div
                onClick={() => handleOptionsArea('niche')}
                className="btns-action open-modal"
              >
                {translate.btnExplore}
                <img src={IconArrow} alt="Arrow" />
              </div>
              <div
                onClick={() => addAllOptions('niche')}
                className="btns-action"
              >
                {translate.btnAddAll}
                <img src={IconAdd} alt="Add" />
              </div>
              <div
                onClick={() => deleteAllOptions('niche')}
                className="btns-action"
              >
                {translate.btnDeleteAll}
                <img src={IconClear} alt="Delete all" />
              </div>
            </div>
            <div className="options-area" ref={divOptionsNiche}>
              {optionsNiche.map((item) => (
                <label key={item}>
                  <input
                    type="checkbox"
                    name={item}
                    value={item}
                    onChange={(e) => toogleSelectedOptions(e, 'niche')}
                  /> {item}
                </label>
              ))}
            </div>
          </QuestionContainer>
          <QuestionContainer>
            <label htmlFor="infoSite">{translate.questionSite}</label>
            <textarea
              name="infoSite"
              value={infos.infoSite}
              onChange={(e) => editFormValues(e)}
              placeholder={translate.placeholderSite}
            />
          </QuestionContainer>
        </QuestionArea>

        <ResponseArea>
          {/* Button #1 Layer */}
          {(!colletedDataFirstStage && !isLoadingData) && (
            <Button
              color="blue"
              onClick={getDataFirstStage}
              disabled={isBtnDisabled}
            >
              {translate.btnOne}
            </Button>
          )}

          {/* #1 Layer Response */}
          {(colletedDataFirstStage) && (
            <ResponseContainer id="first-stage-response">
              <h4>{translate.h4TitleVslCopy}</h4>
              <div className="response-area">
                {userInfo.email === 'suporte+111menu@shifters.com.br' && (
                <>
                  {(promptsResponse?.foundationalCopy && promptsResponse?.foundationalCopy.length > 0) && (
                    <Card $visible={visibleCards.foundationalCopy}>
                      <div
                        className="title"
                        onClick={(e) => handleCardsFoundational('foundationalCopy', e)}
                      >
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {promptsResponse?.foundationalCopy}
                      </div>
                    </Card>
                  )}
                  {(promptsResponse?.foundationalCopyHumanized && promptsResponse?.foundationalCopyHumanized.length > 0) && (
                    <Card $visible={visibleCards.foundationalCopyHumanized}>
                      <div
                        className="title"
                        onClick={(e) => handleCardsFoundational('foundationalCopyHumanized', e)}
                      >
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {promptsResponse?.foundationalCopyHumanized}
                      </div>
                    </Card>
                  )}
                </>
                )}

                {(promptsResponse?.vslSMB && promptsResponse?.vslSMB.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>VSL SMB</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslSMB}
                  </div>
                </Card>
                )}

                {(promptsResponse?.vslEnterprise && promptsResponse?.vslEnterprise.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>VSL Enterprise</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslEnterprise}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #2 Layer Response */}
          {colletedDataSecondStage && (
            <ResponseContainer id="second-stage-response">
              <h4>{translate.h4TitleVslPolicyFree}</h4>
              <div className="response-area">
                {(promptsResponse?.vslSMBPolicyFree && promptsResponse?.vslSMBPolicyFree.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVslSMBPolicyFree}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslSMBPolicyFree}
                  </div>
                </Card>
                )}

                {(promptsResponse?.vslEnterprisePolicyFree && promptsResponse?.vslEnterprisePolicyFree.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleVslEnterprisePolicyFree}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.vslEnterprisePolicyFree}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #3 Layer Response */}
          {(colletedDataThirdStage) && (
            <ResponseContainer id="third-stage-response">
              <h4>{translate.h4TitleAssets}</h4>
              <div className="response-area">
                {(promptsResponse?.headlines && promptsResponse?.headlines.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Headlines</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.headlines}
                  </div>
                </Card>
                )}

                {(promptsResponse?.singleMechanism && promptsResponse?.singleMechanism.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleSingleMechanism}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.singleMechanism}
                  </div>
                </Card>
                )}

                {(promptsResponse?.faqs && promptsResponse?.faqs.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>FAQs</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.faqs}
                  </div>
                </Card>
                )}

                {(promptsResponse?.slideshow && promptsResponse?.slideshow.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleSlideShow}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.slideshow}
                  </div>
                </Card>
                )}

                {(promptsResponse?.linkedinOutboundSMB && promptsResponse?.linkedinOutboundSMB.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn Outbound SMB</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinOutboundSMB}
                  </div>
                </Card>
                )}

                {(promptsResponse?.linkedinOutboundEnterprise && promptsResponse?.linkedinOutboundEnterprise.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>LinkedIn Outbound Enterprise</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.linkedinOutboundEnterprise}
                  </div>
                </Card>
                )}

                {(promptsResponse?.emailOutboundSMB && promptsResponse?.emailOutboundSMB.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>E-mail Outbound SMB</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.emailOutboundSMB}
                  </div>
                </Card>
                )}

                {(promptsResponse?.emailOutboundEnterprise && promptsResponse?.emailOutboundSMB.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>E-mail Outbound Enterprise</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.emailOutboundEnterprise}
                  </div>
                </Card>
                )}

                {(promptsResponse?.salesLetterSMB && promptsResponse?.salesLetterSMB.length > 0) && (
                <Card id="second-stage-response" $visible>
                  <div className="title">
                    <span>{translate.titleSalesConversationalB2C}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.salesLetterSMB}
                  </div>
                </Card>

                )}

                {(promptsResponse?.salesLetterEnterprise && promptsResponse?.salesLetterEnterprise.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleSalesConversationalEnterprise}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.salesLetterEnterprise}
                  </div>
                </Card>
                )}

                {(promptsResponse?.caseStudyB2B && promptsResponse?.caseStudyB2B.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Case Study B2B</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.caseStudyB2B}
                  </div>
                </Card>
                )}

                {(promptsResponse?.productsNames && promptsResponse?.productsNames.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>{translate.titleTwentyNames}</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.productsNames}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #4 Layer Response */}
          {(colletedDataFourthStage) && (
            <ResponseContainer id="fourth-stage-response">
              <h4>{translate.h4TitleEngineValidation}</h4>
              <div className="response-area">
                {(promptsResponse?.orderBump && promptsResponse?.orderBump.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Order Bump</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.orderBump}
                  </div>
                </Card>
                )}

                {(promptsResponse?.upsell && promptsResponse?.upsell.length > 0) && (
                <Card $visible>
                  <div className="title">
                    <span>Upsell</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {promptsResponse?.upsell}
                  </div>
                </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #5 Layer Response */}
          {(colletedDataFifthStage) && (
            <ResponseContainer id="fifth-stage-response">
              <h4>{translate.h4TitleScaleCopy}</h4>
              <div className="response-area">
                {(promptsResponse?.googleAdsKeywords && promptsResponse?.googleAdsKeywords.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleGAKeywords}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.googleAdsKeywords}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.googleAdsAnnouncementGroup && promptsResponse?.googleAdsAnnouncementGroup.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleGAAnnouncementGroup}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.googleAdsAnnouncementGroup}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.googleAdsAnnouncement && promptsResponse?.googleAdsAnnouncement.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleGAAnnouncement}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.googleAdsAnnouncement}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.metaAds && promptsResponse?.metaAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleMetaAds}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.metaAds}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.tiktokAds && promptsResponse?.tiktokAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleTiktokAds}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.tiktokAds}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.linkedinAds && promptsResponse?.linkedinAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.h4TitleLinkedinAds}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.linkedinAds}
                    </div>
                  </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* #6 Layer Response */}
          {(colletedDataSixthStage) && (
            <ResponseContainer id="sixth-stage-response">
              <h4>{translate.h4TitleVideoAds}</h4>
              <div className="response-area">
                {(promptsResponse?.videoAds && promptsResponse?.videoAds.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Video Ads</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAds}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsHistoricalEvolution && promptsResponse?.videoAdsHistoricalEvolution.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsHistoricalEvolution}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsHistoricalEvolution}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsPersonaProblem && promptsResponse?.videoAdsPersonaProblem.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsPersonaPainPoints}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsPersonaProblem}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsTransformationsTimeLapse && promptsResponse?.videoAdsTransformationsTimeLapse.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsTransformationTimeLapse}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsTransformationsTimeLapse}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsWhistleblowerControl && promptsResponse?.videoAdsWhistleblowerControl.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsWhistleblower}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsWhistleblowerControl}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsPermissionSkip && promptsResponse?.videoAdsPermissionSkip.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsPermissionSkip}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsPermissionSkip}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsUnexpectedDelivery && promptsResponse?.videoAdsUnexpectedDelivery.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsUnexpectedDelivery}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsUnexpectedDelivery}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsMithBuster && promptsResponse?.videoAdsMithBuster.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsMithBuster}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsMithBuster}
                    </div>
                  </Card>
                )}

                {(promptsResponse?.videoAdsWeirdHack && promptsResponse?.videoAdsWeirdHack.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleGAVideoAdsWeirdHack}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {promptsResponse?.videoAdsWeirdHack}
                    </div>
                  </Card>
                )}
              </div>
            </ResponseContainer>
          )}

          {/* Loader or ButtonsLayer */}
          {(!buttonsArea.show && isLoadingData)
            && (
              <LoaderContainer>
                <LoaderContainerMemo
                  count={countLoader}
                  message={!colletedDataFirstStage ? translate.messageProccessInfo : translate.messageProccessInfoNoSec}
                  className="progress_bar-area"
                />
              </LoaderContainer>
            )}

          {buttonsArea.show && (
            <ButtonsArea
              buttonsArea={buttonsArea}
              onGetDataSecondStage={getDataSecondStage}
              onGetDataThirdStage={getDataThirdStage}
              onGetDataFourthStage={getDataFourthStage}
              onGetDataFifthStage={getDataFifthStage}
              onGetDataSixthStage={getDataSixthStage}
            />
          )}


        </ResponseArea>

      </Container>
    </>
  );
}
