import React, { useContext } from 'react';
import {
  Routes, Route, Navigate, Outlet,
} from 'react-router-dom';
import Login from '../pages/Login/Login';
import { RecoveryPassword } from '../pages/RecoveryPassword';
import IndexUser from '../pages/IndexUser';
import IndexAdm from '../pages/IndexAdm';
import Trial from '../pages/Trial';
import NotFound from '../pages/NotFound';
import { PrivacyPolicy } from '../pages/PrivacyPolicy';
import { TermsAndCondition } from '../pages/TermsAndCondition';
import { AuthContext } from '../context/AuthContext';

function ProtectedRoutes({ isAllowed, loading, children }) {
  if (loading) {
    return <div> Loading Infos...</div>;
  }

  if (!isAllowed) {
    return <Navigate to="/" replace />;
  }

  return children || <Outlet />;
}

export default function Router() {
  const { authenticated, loading, userInfo: { perfil } } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route element={(<ProtectedRoutes isAllowed={(authenticated && (perfil === 'B2B' || perfil === 'B2C'))} loading={loading} />)}>
        <Route path="/client/*" element={<IndexUser />} />
      </Route>
      <Route element={(<ProtectedRoutes isAllowed={(authenticated && perfil === 'SHIFTER')} loading={loading} />)}>
        <Route path="/admin/*" element={<IndexAdm />} />
      </Route>
      <Route path="/recovery-password" element={<RecoveryPassword />} />
      <Route path="/trial" element={<Trial />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
