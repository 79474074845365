export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    newProject: language === 'pt-BR' ? 'Novo Lab' : 'New Lab',
    newProjectMsg: language === 'pt-BR'
      ? 'Clique aqui e acesse nossas ferramentas de IA'
      : 'Click here and access our AI tools',
    recents: language === 'pt-BR' ? 'Recentes' : 'Recents',
    noDataMsg: language === 'pt-BR' ? 'Você não possui projetos recentes' : 'You have no recent projects',
    language,
  };
}
