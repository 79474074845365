import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: 16px;
  }

  .title-infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    span {
      color: ${({ theme }) => theme.gray4}
    }

    .title-area {
      display: flex;
      align-items: center;
      gap: 16px;

      h2 {
        font-weight: 500;
      }

      img {
        width: 17px;
      }

      a:hover img {
        filter: invert(94%) sepia(100%) saturate(727%) hue-rotate(143deg) brightness(510%) contrast(84%);
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.smartphone}) {
      h2, p, a {
        font-size: 13px;
        word-wrap: break-word;
        white-space: pre-wrap;
      }

      img {
        width: 14px;
      }
    }
  }

  .title-actions {
    button {
      padding: 4px 16px;

      &:hover img {
        filter: invert(57%) sepia(99%) saturate(5000%) hue-rotate(359deg) brightness(100%) contrast(100%);
      }

      img {
        width: 15px;
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
      }
    }
  }
`;

export const EntranceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  h3 {
    font-weight: 500;
    color: ${({ theme }) => theme.blue};
  }

  .info-group-d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
    }
  }

  .info-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border:1px solid ${({ theme }) => theme.gray1};
    border-radius: 4px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }

    label {
      background-color: ${({ theme }) => theme.gray1};
      width: 100%;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      padding: 8px;
      font-weight: 500;
    }

    div {
      background-color: ${({ theme }) => theme.grayMenu};
      width: 100%;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 8px;
      // Important to format texts in each card
      white-space: pre-wrap;
      word-wrap: break-word;
      max-height: 200px;
      overflow-y: auto;
    }
  }
`;

export const ResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 32px;

  h3 {
    font-weight: 500;
    color: ${({ theme }) => theme.blue};
  }

  .container-painels {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .container-layers {
    h4 {
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 8px;
      margin-bottom: 16px;
      border-bottom: 1px solid ${({ theme }) => theme.gray4};
      color: ${({ theme }) => theme.gray4}
    }

    .no-info {
      font-style: italic;
      font-size: 14px;
    }
  }
`;

export const ResponseCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 32px 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.gray1};
  border-radius: 4px;
  height: ${({ $visible }) => ($visible ? '550px' : '20px')};
  display: flex;
  flex-direction: column;

    .title {
      background-color:${({ theme }) => theme.gray1};
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      padding: 8px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      width: 100%;

      span {
        cursor: ${({ $visible }) => ($visible ? 'normal' : 'pointer')};
        width: 100%;
        text-align: center;
        font-weight: 500;
      }

      img {
        cursor: pointer;
        display: inline-block;
        transition: 0.2 ease-in-out;
        filter: invert(99%) sepia(7%) saturate(312%) hue-rotate(195deg) brightness(113%) contrast(89%);
        width: 13px;
      }

      img:hover {
        filter: invert(73%) sepia(0%) saturate(169%) hue-rotate(196deg) brightness(91%) contrast(85%);
      }
    }

    .infos {
      overflow-y: scroll;
      flex: 1;  /* Substitua height: 100% por flex: 1 */
      padding: 16px;
      background-color:${({ theme }) => theme.grayMenu};
      height: inherit;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      display: ${({ $visible }) => ($visible ? 'inline-block' : 'none')};

      // Important to format texts in each card
      white-space: pre-wrap;
      word-wrap: break-word;
    }
`;

