import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${(props) => props.theme.sizes.s40};

  img {
    margin-bottom: ${(props) => props.theme.sizes.s32};
  }

  p {
    margin-bottom: ${(props) => props.theme.sizes.s16};
    font-size: 16px;
  }

  .container-terms {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 4px;
  }
`;
