export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  const terms = {
    txtCopyCut: 'AI tool that transforms 3 inputs into powerful persuasive texts. Generates adaptable VSLs, impactful sales letters, converting headlines and a complete copywriting suite.',
    txtTranslateAI: 'Complete translation solution that combines the best of both worlds: literal precision when needed and intelligent contextual adaptation, respecting every cultural and linguistic nuance.',
    badge: 'Coming soon',
    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        txtCopyCut: 'Ferramenta de IA que transforma 3 inputs em poderosos textos persuasivos. Gera VSLs adaptáveis, cartas de vendas impactantes, headlines que convertem e uma suite completa de copywriting.',
        txtTranslateAI: 'Solução completa de tradução que une o melhor dos dois mundos: precisão literal quando necessário e adaptação contextual inteligente, respeitando cada nuance cultural e linguística.',
        badge: 'Em breve',
        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        txtCopyCut: 'Ferramenta de IA que transforma 3 inputs em textos persuasivos poderosos. Gera VSLs adaptáveis, cartas de vendas impactantes, títulos que convertem e uma suite completa de copywriting.',
        txtTranslateAI: 'Solução completa de tradução que une o melhor dos dois mundos: precisão literal quando necessário e adaptação contextual inteligente, respeitando cada nuance cultural e linguística.',
        badge: 'Em breve',
        language,
      };

    case 'es-ES':
      return {
        ...terms,
        txtCopyCut: 'Herramienta de IA que transforma 3 entradas en poderosos textos persuasivos. Genera VSLs adaptables, cartas de ventas impactantes, títulos que convierten y una suite completa de copywriting.',
        txtTranslateAI: 'Solución completa de traducción que une lo mejor de ambos mundos: precisión literal cuando es necesario y adaptación contextual inteligente, respetando cada matiz cultural y lingüístico.',
        badge: 'Próximamente',
        language,
      };

    default:
      return terms;
  }
}
