import React from 'react';
import { Container } from './style';

export function TermsContent() {
  return (
    <Container>
      <h2>Termos e Condições</h2>

      <h3>Aceitação das condições de uso</h3>
      <p>Antes de utilizar esta Página da internet (Site) leia com atenção os presentes Termos e Condições de Uso. Ao utilizar este site, você estará automaticamente concordando com todos os termos, condições e avisos aqui contidos ou mencionados (Condições de Uso). Solicitamos não utilizar este site caso você não concorde com as presentes Condições de Uso. A SHIFTERS se reserva ao direito de modificar, alterar, atualizar ou, de outra forma, revisar os presentes Termos e Condições de Uso. Por favor, verifique as mudanças periodicamente efetuadas nos presentes Termos e Condições de Uso. Ao utilizar este site após divulgação de aviso relativo a quaisquer dessas mudanças, você estará automaticamente concordando em submeter-se a tais modificações, alterações, atualizações ou revisões.
      </p>

      <h3>Marcas Registradas e Direitos Autorais</h3>
      <p>As marcas, nomes, logotipos, nomes de domínio e demais sinais distintivos, bem como todo e qualquer conteúdo, desenho, arte ou layout publicado no site são de propriedade exclusiva da SHIFTERS. São vedados quaisquer atos ou contribuições tendentes à descompilação, engenharia reversa, modificação das características, ampliação, alteração, mesclagem ou incorporação em quaisquer outros programas ou sistemas. Enfim, toda e qualquer forma de reprodução, total ou parcial, permanente, temporária ou provisória, de forma gratuita ou onerosa, sob quaisquer modalidades, formas ou títulos do site ou do SHIFTER SOFTWARE é expressamente vedada.
      </p>

      <h3>Politica de Privacidade</h3>
      <p>A SHIFTERS respeita a privacidade dos visitantes e usuários do site. Para aceder à politica de privacidade acessar.
      </p>

      <h3>Limitação de responsabilidade</h3>
      <p>Em hipótese alguma a SHIFTERS, suas subsidiárias ou licenciantes serão responsáveis por quaisquer danos diretos, indiretos, punitivos, emergentes, especiais ou imprevistos resultantes da utilização ou da incapacidade de utilizar este site. Essa limitação será aplicável quer a alegada responsabilidade tenha por base contrato, ilícito civil, negligência, responsabilidade objetiva ou qualquer outro fundamento, mesmo que a SHIFTERS tenha sido informada da possibilidade de tal dano. No caso das jurisdições não permitirem a exclusão ou limitação de danos emergentes ou imprevistos, a responsabilidade da SHIFTERS, nessas jurisdições, será limitada à extensão permitida pela lei.
      </p>

      <h3>Sanções</h3>
      <p>O uso deste site de forma indevida e em desacordo com estes Termos e Condições de Uso implicará a exclusão do cadastro do Usuário e a proibição da utilização dos serviços. Seus dados serão preservados para uso das autoridades competentes, caso a SHIFTERS seja notificada ou acionada pela violação de direitos de terceiros decorrente do mau uso do sistema. O Usuário concorda em indenizar a SHIFTERS, suas filiais, empresas controladas ou controladoras, diretores, administradores, colaboradores, representantes e empregados por qualquer perda, responsabilização, reclamação ou demanda, por quaisquer prejuízos devido a, ou resultantes da, sua utilização do site.
      </p>

      <h3>Garantias</h3>
      <p>O conteúdo deste site é licenciado “AS IS” (“como é”) não prestando a SHIFTERS qualquer garantia expressa ou implícita, incluindo, mas não se limitando à disponibilidade, precisão e conteúdo desse site. A SHIFTERS não será responsável por quaisquer danos diretos, indiretos, acidentais ou lucros cessantes pela eventual interrupção de negócios decorrentes do uso ou incapacidade de usar este site.
      </p>

      <h3>Jurisdição</h3>
      <p>Estes Termos e Condições de Uso são regidas pela legislação Brasileira. Fica eleito o Foro da Comarca de São Paulo, Estado de São Paulo, com exclusão de qualquer outro, por mais privilegiado que seja, para dirimir as controvérsias que possam surgir na execução dos presentes Termos e Condições de Uso.
      </p>
    </Container>
  );
}
